import React from 'react';
import CookieConsent from 'react-cookie-consent';
import {
    Link
  } from 'react-router-dom';
export default class Cookies extends React.Component {
    render() {
        return (
            <>
                <CookieConsent
                    location="bottom"
                    buttonText="Accept Cookies!!"
                    cookieName="myAwesomeCookieName2"
                    style={{ background: "#242424" }}
                    buttonStyle={{ color: "#242424", fontSize: "13px" }}
                    expires={360}
                >
                    This site uses cookies. By continuing to use this website, you agree to their use. See <Link to="/privacypolicy" style={{ color: '#FFF' }}>Privacy policy</Link> for more info.
                </CookieConsent>
            </>

        )
    }
}