import React, { useEffect, useCallback, useState } from 'react';
import './Header.css';
import { Navbar, Nav,Button } from 'react-bootstrap';
import { useLocation, Link } from "react-router-dom";
import {isMobile} from 'react-device-detect';


const Header = (props) => {
    const { location } = props;
    const white = '#f8f9fa';
    const { pathname } = useLocation();
    const isHeaderTransparentPage = !isMobile ? (pathname === '/') || (pathname === '/courses') : false;
    const [ bg, setBg ] = useState(!isHeaderTransparentPage ? white : 'transparent');
    const [ whitelogo, setwhiteLogo] = useState(isHeaderTransparentPage ? '/images/logo-hori-white.png' :'/images/logo-hori-black.png')
    const [ whiteStyle, setWhiteStyle ] = useState(isHeaderTransparentPage ? { color: "white" } : {color:"black"})
    const extraProps = isHeaderTransparentPage ? { fixed: "top" } : {fixed: "top" };

    const onScroll = useCallback(
        () => {
            if(isMobile){
                setBg(white);
                setWhiteStyle({});
                setwhiteLogo('/images/logo-hori-black.png');
            }else{
            if( window.scrollY > 20 )  {
                setBg(white);
                setWhiteStyle({});
                setwhiteLogo('/images/logo-hori-black.png');
            }else{
                setBg('transparent');
                setWhiteStyle({color: "white"});
                setwhiteLogo('/images/logo-hori-white.png');
            }

            if(!isHeaderTransparentPage){
                setBg(white);
                setWhiteStyle({color: "black"});
                setwhiteLogo('/images/logo-hori-black.png');
            }
        }
        },
        [isHeaderTransparentPage]
    )
    useEffect(() => {
        window.addEventListener('scroll', onScroll)
    }, [onScroll])
    useEffect(() => {
        if(isMobile){
            setBg(white);
            setWhiteStyle({});
            setwhiteLogo('/images/logo-hori-black.png');
        }else{
        if(!isHeaderTransparentPage){
            setBg(white);
            setWhiteStyle({color:"black"});
            setwhiteLogo('/images/logo-hori-black.png');
        }else{
            setBg('transparent');
            setWhiteStyle({color: "white"});
            setwhiteLogo('/images/logo-hori-white.png');
        }
    }
    }, [isHeaderTransparentPage])
    return (
        <div className="header">
            <Navbar {...extraProps} bg="" expand="lg" style={{ backgroundColor: bg }}>
                <div className="container">
                    <Navbar.Brand style={whiteStyle} as={Link} to="/">
                        <img
                            src= {whitelogo}
                            width="200"
                            className="d-inline-block align-top"
                            alt="alisha_logo"
                        /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto" activeKey={location.pathname}>
                            <Nav.Link style={whiteStyle} as={Link} to="/bio" href="/bio">Bio</Nav.Link>
                            <Nav.Link style={whiteStyle} as={Link} to="/portfolio" href="/portfolio">Portfolio</Nav.Link>
                            <Nav.Link style={whiteStyle} as={Link} to="/services" href="/services">Services</Nav.Link>
                            <Nav.Link style={whiteStyle} as={Link} to="/courses" href="/courses">Courses</Nav.Link>
                            <Nav.Link style={whiteStyle} as={Link} to="/blog" href="/blog" target={"_blank"}>Blog</Nav.Link>
                            <Nav.Link style={whiteStyle} as={Link} to="/contact" href="/contact">Contact</Nav.Link>
                        </Nav>
                        <Button variant="warning" >
                        <Nav.Link as={Link} to="/booknow">Book Now </Nav.Link></Button>

                    </Navbar.Collapse>
                </div>
            </Navbar>

        </div>
    )
}

export default Header;
