import { withRouter } from "react-router";
import './App.css';
import Header from './Components/Header/Header';
import Bio from './Components/Bio/Bio';
import Portfolio from './Components/Portfolio/Portfolio';
import Services from './Components/Services/Services';
import Courses from './Components/Courses/Courses';
import Footer from './Components/Footer/Footer';
import Cookies from './Components/Cookies/Cookies';
import Contactus from './Components/Contactus/Contactus';
import BookNow from './Components/BookNow/BookNow';
import Home from './Components/Home/Home';
import CancellationPolicy from './Components/CancellationPolicy/CancelPolicy';
import Terms from './Components/Terms/Terms';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

const HeaderWithRouter = withRouter(Header);

function App() {
  return (
    <div className="App">
      <Router>
        <HeaderWithRouter />
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/bio" component={Bio}></Route>
          <Route path="/portfolio" component={Portfolio}></Route>
          <Route path="/services"  component={Services}></Route>
          <Route path="/courses" component={Courses} ></Route>
          <Route path="/blog" exact render={() => (window.location = "http://alishaasah.com/blog")} ></Route>
          <Route path="/contact" component={Contactus}></Route>
          <Route path="/booknow" component={BookNow}></Route>
          <Route path="/terms" component={Terms}></Route>
          <Route path="/cancelpolicy" component={CancellationPolicy}></Route>
        </Switch>
        <Footer></Footer>
        <Cookies></Cookies>
        <ScrollToTop />
      </Router>
    </div>
  );
}

export default App;
