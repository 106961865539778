import React from 'react';
import './Courses.css';
import portfolio1 from '../../images/alisha-courses.png';

export default class Courses extends React.Component {
    render() {
        return (
            <div >
                <section className="courses-banner-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 mt-5">
                                <img className="pl-2 mt-5" src={portfolio1} alt="portfolio1" />
                            </div>
                            <div className="col-sm-6 text-left m-auto banner-info">
                                <h1 className="outline-text mt-5">MASTER</h1>
                                <h1 className="bold-text">CLASS</h1>
                                <p>We offer an All-inclusive specialized Makeup course. 
                                    Classes will be held by Alisha Sah Academy for people who are serious 
                                    about entering the beauty industry to get a powerful head start for their careers.
                                </p>
                                <p><b>What you will receive by doing a course?</b><br />
                                    <ul>
                                        <li>A certificate signed by Alisha Sah, a pro makeup artist, confirming that you have skilfully mastered the fundamentals of make-up.</li>
                                        <li>A professional Q&A session with Alisha Sah where you can ask all about your make-up related questions and get some solutions. </li>
                                        <li>Comprehensive knowledge and insight into the beauty industry. </li>
                                        <li>Make-up and styling guidelines, tips and tricks. </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="upcomingcourses-section py-5">
                    <div className="container">
                        <div className="yellow-stroke m-auto"></div>
                        <h2 className="my-3">Upcoming courses</h2>
                        <p className="mt-5"><span>  Coming soon </span></p>

                    </div>
                </section>
            </div>

        )
    }
}