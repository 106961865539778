import React, { useState, useCallback } from 'react';
import ImageViewer from "react-simple-image-viewer";
import portfolio2 from '../../images/portfolio2.png';
import portfolio3 from '../../images/portfolio3.svg';
import customer1 from '../../images/c1Vaishal.jpg';
import customer2 from '../../images/c2aakhansha.jpeg';
import customer3 from '../../images/c3NishiAlbert.jpg';
import customer4 from '../../images/c4SaswataDebnath.jpg';
import customer5 from '../../images/c5EvaWalter.jpg';
import customer6 from '../../images/c6ArtiRathore.jpg';
import alishaatripple from '../../images/alishatripple.png';
import './Bio.css';

const ImageSize = new Array(6);
const defaultReviewText = '"Her knowledge of hypoallergenic products assured me as I have very sensitive skin, this is the first time I experienced the beauty of make-up without any rashes or breakouts, Alishaa has great info about every skin type and its very empathetic of her to complied with my skin needs so gracefully."'
const CUSTOMER_REVIEWS = [
    {
        "id":1,
        "name":"Sheela",
        "comments":'"I always had low self-esteem regarding my looks even after I did my make-up, turns out I wasn’t doing is right for my face, Alishaa made a huge difference with her skills, it takes a pro to know what works for individual, I’m happy that I chose her as my make-up artist, will be making more visits!"',
        "image" : customer1
    },
    {
        "id":2,
        "name":"Meghna",
        "comments":'"Alishaa has a calm and charismatic energy about her, getting an appointment with her feels so therapeutic in a beauty-related way like you end up leaving feeling more attractive, I just love that she lifts my mood and makes me look pretty"',
        "image" : customer2
    },
    {
        "id":3,
        "name":"Vaishnavi",
        "comments":'“It was my first time getting my makeup done by a professional makeup artist for my first shoot, I was nervous but she made me feel at ease and I got so many compliments at the shoot, my day was made! She has made me stand out on my special days and I’m grateful that makeup artists like Alishaa exist, trust me it makes a huge difference getting your makeup don’t by a pro, it shows on your face, I recommend her services 100%. “',
        "image" : customer3
    },
    {
        "id":4,
        "name":"Hema",
        "comments":'"I went in unsure what I want more than a trim but came out with a complete makeover I never knew I needed! It was an amazing experience and I will keep coming back because Alishaa knows exactly the way I want to look; unlike many makeup and beauty professionals she lets me give inputs and works with me with a positive approach which I admire a lot."',
        "image" : customer4
    },
    {
        "id":5,
        "name":"Shuma",
        "comments":'"Got Alishaas reference by a close friend and instantly booked an appointment, after getting my chic make-up done, I went for a fabulous hairstyle, Im satisfied by the services and the prices are very reasonable for such elite skills, shes a passionate pro MUA." ',
        "image" : customer5
    },
    {
        "id":6,
        "name":"Keerime",
        "comments":'"Her knowledge of hypoallergenic products assured me as I have very sensitive skin, this is the first time I experienced the beauty of make-up without any rashes or breakouts, Alishaa has great info about every skin type and its very empathetic of her to complied with my skin needs so gracefully."',
        "image" : customer6
    }
];

const Gallery = ({images, title}) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const openImageViewer = useCallback(index => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);
  
    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };
    const viewerImages = images.map(({image}) => image);
    return <div>
      <div className="bio-gallery"> 
        {
          Array.isArray(images) ? images.map(({image}, index) => {
            return <div className="bio-gallery-item">
               <img  alt="bio-gallery Images"  className="bio-gallery-image" src={image} style={{width: '100%'}} onClick={() => openImageViewer(( index ))}/>
            </div>
          }) : []
        }
      </div>
      {isViewerOpen && (
          <ImageViewer
            src={viewerImages}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)"
            }}
          />
        )}
    </div>
  }
export default class Bio extends React.Component {

    state = {
        images: [],
        reviewText : defaultReviewText,
        reviewImage : customer6
    }

    componentDidMount(){
        const images = [];
        for(let i = 0; i < ImageSize.length; i++){
        const image = {index: i, image: `images/shoots/bio${i+1}.jpg`};
        images.push(image);
        }
        this.setState({
            images
        })
    }

    onMouseOverReviews= (e) =>{
        console.log(e.target.getAttribute("data-review"));
        this.setState({
            reviewText : e.target.getAttribute("data-review"),
            reviewImage : e.target.getAttribute("review-image")
        })
    }

    onMouseLeaveReviews = (e) => {
        this.setState({
            reviewText: defaultReviewText,
            reviewImage : customer6
        })
    }

    render() {
        const { images } = this.state;
         
        return (
            <div id="bio-id">
                <section className="bio-banner-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 text-left m-auto banner-info">
                                <h1 className="outline-text">MAKEUP</h1>
                                <h1 className="bold-text">ARTIST</h1>
                                <p>Beauty lies in everyone, let me introduce you to yours</p>
                                <a href = "#/booknow"> <button type="button" className="btn btn-outline-secondary">Book an appointment</button></a>
                            </div>
                            <div className="col-sm-6 mt-5">
                                <img className="pl-2" src={alishaatripple} alt="alishaatripple" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* Alisha Workfolio*/}
                <section className="workfolio-section">
                    <div className="container ">
                        <div className="row">
                            <div className="col-sm-6 mt-4">
                                <img className="pl-2" src={portfolio2} alt="portfolio1" />
                            </div>
                            <div className="col-sm-6 text-left m-auto">
                                <div className="yellow-stroke mb-3"></div>
                                <h3 className="mb-3">Her way</h3>
                               <p>Alishaa has her own unique way about doing everything beauty related, makeup and hairstyles are customized for each client according to their needs, there’s no one box fits all, it’s the hard work and dedication towards her clients that makes her stand out from the crowd of makeup artists, being in a commercialized competitive space such as the beauty industry</p>
                               <p>Alishaa keeps a positive and holistic approach towards beauty, for her it truly comes from within, she will surely build your confidence with each stroke of her make-up brushes. </p>
                                                         </div>
                        </div>
                    </div>
                </section>

                {/* Alisha PersonalFolio*/}
                <section className="personal-section">
                    <div className="container my-5">
                        <div className="row my-5">
                            <div className="col-sm-6 text-left m-auto">
                                <div className="yellow-stroke mb-3"></div>
                                <h3 className="mb-3">Beauty with brain</h3>
                                <p>Alishaa is a professional makeup artist, Make-up Educator, an Internationally certified cosmetologist and an influencer. </p>
                                <p>Sah got certified in Christine Valmy of Beauty (Pune) in CIDESCO, CIBTAC and ICL. Later got selected by Steiner School of Beauty London UK, where she pursued her Master/Professional training in Skin, Hair, Body treatments by ELEMIS and became Beauty and Body Specialist (Elemis) and was working on Holland America Cruise Lines for 1 year.</p>
                                <p>With adequate experience in the film industry’s makeup field and working with professional actors and models, Alishaa has mastered the art of make-up within challenging environments and racing deadlines. </p>
                                <p>In 2018, Sah founded RBS Salon & Makeup Studio and ASI Academy of Beauty, headquartered in Surat, Gujarat India. At her academy, Sah offers all-inclusive specialized cosmetology courses for beginners who want to learn about makeup and the beauty industry at large, with more than 10 years of work experience in the beauty industry, she’s undeniably a pro at what she does.  </p>
                            </div>
                            <div className="col-sm-6 mt-4">
                                <img className="pl-2" src={portfolio3} alt="portfolio1" />
                            </div>
                        </div>

                        <Gallery images={images}/>
                    </div>

                </section>
                {/* Alisha gallery*/}


                {/* Alisha Customer Reviews*/}
                <section className="reviews-section">
                    <div className="container">
                        <div className="row">
                            <div className="round-display col-sm-4 d-flex align-items-start my-5 pr-5">
                                <img className="ml-auto rounded-circle" src={CUSTOMER_REVIEWS[0].image} alt="portfolio1" onMouseOver = {this.onMouseOverReviews} data-review = {CUSTOMER_REVIEWS[0].comments} review-image = {CUSTOMER_REVIEWS[0].image} onMouseLeave={this.onMouseLeaveReviews} onClick = {this.onMouseOverReviews}/>
                                <img className="m-auto rounded-circle" src={CUSTOMER_REVIEWS[1].image} alt="portfolio2" onMouseOver = {this.onMouseOverReviews} data-review = {CUSTOMER_REVIEWS[1].comments}  review-image = {CUSTOMER_REVIEWS[1].image} onMouseLeave={this.onMouseLeaveReviews} onClick = {this.onMouseOverReviews} />
                                <img className="ml-auto rounded-circle" src={CUSTOMER_REVIEWS[2].image} alt="portfolio3" onMouseOver = {this.onMouseOverReviews} data-review = {CUSTOMER_REVIEWS[2].comments} review-image = {CUSTOMER_REVIEWS[2].image} onMouseLeave={this.onMouseLeaveReviews} onClick = {this.onMouseOverReviews} />
                            </div>
                            <div className="col-sm-4 mt-4">
                                <div className="yellow-stroke m-auto"></div>
                                <h3 className="my-3">What our clients say</h3>
                                <img className="pl-2 rounded-circle" src={this.state.reviewImage} alt="portfolio1" onMouseOver = {this.onMouseOverReviews} data-review = {CUSTOMER_REVIEWS[5].comments} review-image = {CUSTOMER_REVIEWS[5].image} onMouseLeave={this.onMouseLeaveReviews} onClick = {this.onMouseOverReviews}/>
                                   <p className="mt-4">{this.state.reviewText}</p> 
                           </div>
                            <div className=" col-sm-4 d-flex align-items-start my-5 round-display pl-5">
                                <img className="mr-auto rounded-circle" src={CUSTOMER_REVIEWS[3].image} alt="portfolio5" onMouseOver = {this.onMouseOverReviews} data-review = {CUSTOMER_REVIEWS[3].comments} review-image = {CUSTOMER_REVIEWS[3].image} onMouseLeave={this.onMouseLeaveReviews} onClick = {this.onMouseOverReviews}/>
                                <img className="m-auto rounded-circle" src={CUSTOMER_REVIEWS[4].image} alt="portfolio4" onMouseOver = {this.onMouseOverReviews} data-review = {CUSTOMER_REVIEWS[4].comments} review-image = {CUSTOMER_REVIEWS[4].image} onMouseLeave={this.onMouseLeaveReviews} onClick = {this.onMouseOverReviews}/>
                                <img className="mr-auto rounded-circle" src={CUSTOMER_REVIEWS[5].image} alt="portfolio6" onMouseOver = {this.onMouseOverReviews} data-review = {CUSTOMER_REVIEWS[5].comments} review-image = {CUSTOMER_REVIEWS[5].image} onMouseLeave={this.onMouseLeaveReviews} onClick = {this.onMouseOverReviews}/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}