import React, { useState, useCallback } from 'react';
import './Portfolio.css';
import ImageViewer from "react-simple-image-viewer";
import ModalVideo from 'react-modal-video';

const ImageSize = new Array(11);
const bridalImageSize = new Array(12);
const commercialImageSize = new Array(12);
function* chunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

const Gallery = ({images, title}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const splittedImages = [...chunks(images, 2)];
  const viewerImages = images.map(({image}) => image);
  return <div>
    <div className="gallery"> 
      {
        Array.isArray(images) ? images.map(({image}, index) => {
          return <div className="gallery-item">
             <img  alt="Gallery Images"  className="gallery-image" src={image} style={{width: '100%'}} onClick={() => openImageViewer(( index ))}/>
          </div>
        }) : []
      }
    </div>
    {isViewerOpen && (
        <ImageViewer
          src={viewerImages}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
        />
      )}
  </div>
}

const VideoPlayer = ({videoId, thumbnail}) => {
  const [isOpen, setOpen] = useState(false)
  return <>
    <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)}/>
    <div className="video-viewer gallery-item" onClick={()=> setOpen(true)}>
        <img className="gallery-image" src={thumbnail} alt="thumbnail"/>
    </div>
  </>
}

class EmbedSocialWidget extends React.Component{
  componentDidMount(){
    (function(d, s, id){var js; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/cdn/ht.js"; d.getElementsByTagName("head")[0].appendChild(js);}(document, "script", "EmbedSocialHashtagScript"))
  }
  render(){
    return <div className="embedsocial-hashtag" data-ref="926668347fabda1df5dc3c5faf880634e6e2bd57"/>
  }

}

export default class Portfolio extends React.Component{

  state = {
    images: [],
    bridalImages : [],
    commercialImages : []
  }

  componentDidMount(){
    const videos = [
      {
        videoId:'x7s5LcpzTu8',
        thumbnail: "/images/y-1.png"
      },
      {
        videoId:'SoHWgSEjfO8',
        thumbnail: "/images/y-2.png"
      },
      {
        videoId:'N-0TsPNbvCU',
        thumbnail: "/images/y-3.png"
      },
      {
        videoId:'aluBAJT3Rls',
        thumbnail: "/images/y-4.png"
      }
    ]
    const images = [];
    const bridalImages = [];
    const commercialImages = [];

    for(let i = 0; i < bridalImageSize.length; i++){
      const image = {index: i, image: `images/shoots/bridal${i+1}.jpg`};
      bridalImages.push(image);
    }

    for(let i = 0; i < commercialImageSize.length; i++){
      const image = {index: i, image: `images/shoots/commercial${i+1}.jpg`};
      commercialImages.push(image);
    }

    this.setState({
      videos, images,bridalImages,commercialImages
    })
  }

  render(){
    const { images, videos,bridalImages,commercialImages } = this.state;
    return <div className="protfolio__container">
      <div className="portfolifo-viewer">
        <div className="title-bar"></div>
        <div className="portfolifo-view-title">BRIDAL WORKS</div>
        <p>Bridal makeup needs a whole new level of professional expertise. Alishaa feels extremely honoured when she puts her expertise and experience to use for a bride-to-be, there's nothing more rewarding than to see a smile of satisfaction on the brides she works on for their big day, each product used for bridal makeup is chosen with extra carefulness, keeping in mind the time it has to stay on your face to the minimum touch-ups it may require, Alishaa has worked with many beautiful brides and below are some of the looks she has created</p>
        <Gallery images={bridalImages}/>
      </div>
      <div className="portfolifo-viewer">
        <div className="title-bar"></div>
        <div className="portfolifo-view-title">COMMERCIAL SHOOTS</div>
        <p>As an art form make-up is quite flexible given it’s performed by a Pro artist. 
        For every look imaginable, Alishaa has a clear vision, be it a high-class party, a premiere, bridal shower or a throwback birthday party within the block, we have you covered.
        Here are some of the various commercial makeup looks. 
        </p>
        <Gallery images={commercialImages}/>
      </div>
      <div className="portfolifo-viewer">
        <div className="title-bar"></div>
        <div className="portfolifo-view-title">OCCASIONAL WORKS</div>
        <p className="mt-3 coming-soon"><span>  Coming soon </span></p>
      </div>
        <div className="portfolifo-viewer">
        <div className="title-bar"></div>
        <div className="portfolifo-view-title">VIDEO LOGS</div>
        <p>Alishaa loves to share beauty related content on social media, for people to enjoy and learn in the process, the passion speaks in itself on these videos - </p>
        <div className="video-list gallery">
          {
            Array.isArray(videos) ? videos.map(props  => <VideoPlayer {...props}/>) : null
          }
        </div>
      </div>
      <EmbedSocialWidget/>
    </div>
  }
}