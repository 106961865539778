import React from 'react';
import './Terms.css'
export default class Terms extends React.Component {
    render() {
        return (
           <div>
                <p>Terms and conditions</p>
            </div>
        )
    }
}