export const CUSTOMER_REVIEWS = [
    {
        "id":1,
        "name":"Sheela",
        "comments":"Very fast. Proper safety ke sath very polite  khyati  thank you  urban",
        "image" : "review1.png"
    },
    {
        "id":2,
        "name":"Meghna",
        "comments":"Mrs Alisha was very experienced that shows in her service she was very friendly as well as she has done very good threading and waxing big thumbs up to her service.",
        "image" : "review1.png"
    },
    {
        "id":3,
        "name":"Vaishnavi",
        "comments":"The makeup is amazing to the core, I couldnt take my eyes off my face. Stunningly fabulus from Alishaa. You are the best dear",
        "image" : "review1.png"
    },
    {
        "id":4,
        "name":"Hema",
        "comments":"Her permanent rating should be 5*. Perfect service. Clean and hygenic. Mature in behavior. Jesal is skillful too. Great service",
        "image" : "review1.png"
    },
    {
        "id":5,
        "name":"Shuma",
        "comments":"Alisha is very polite n very professional . One feel very comfortable with her she is excellent in her job specially in facial while removing black heads you don’t feel pain at all she removes blackheads in a very soft way  Thanku so much Alisha ",
        "image" : "review1.png"
    },
    {
        "id":6,
        "name":"Keerime",
        "comments":"The makeup is amazing to the core, I couldnt take my eyes off my face. Stunningly fabulus from Alishaa. You are the best dear",
        "image" : "review1.png"
    }
];

export const SERVICES_PACKAGE = [
    {
        "id":1,
        "title":"** Bridal Makeup Inclusion **",
        "desc":"Makeup , Hair styling , drapping , False Eyelashes , Nail Paint changes ",
    },
    {
        "id":2,
        "title":"** Bridal Makeup Exclusion **",
        "desc":"Hair Extension , Hair accessories , Nail Extensions , Nail Art , Flower , Gajra ",
    },
    {
        "id":3,
        "title":"** Occasional /Commercial  Makeup Inclusion **",
        "desc":"Makeup , Hairstyle , Drapping ",
    },
    {
        "id":4,
        "title":"** Occasional /Commercial  Makeup EXCLUSION **",
        "desc":"Eyelashes  , Hair Extension , Hair accessories , Nail Extensions , Nail Art , Flower , Gajra ",
    }
]

export const ALISHA_CHARGES = {
    "studio": {
        "bridal": "20,000",
        "occasional": "15,000",
        "commercial": "15,000",
    },
    "venue": {
        "bridal": "25,000",
        "occasional": "20,000",
        "commercial": "20,000",
    },
};

export const SENIOR_CHARGES = {
    "studio": {
        "bridal": "10,000",
        "occasional": "10,000",
        "commercial": "10,000",
    },
    "venue": {
        "bridal": "13,000",
        "occasional": "15,000",
        "commercial": "15,000",
    },
};