import React from 'react';
import './Services.css';
import commercial from '../../images/commercial.png';
import bridal from '../../images/bridalpricing.png';
import occasional from '../../images/occasional.png';
import makeup from '../../images/lip-gloss.png';
import haircut from '../../images/scissors.png';
import hairstyle from '../../images/lotion.png';
import brows from '../../images/eyelashes.png';

import * as MASTERDATA from '../../Constants/data-fillers';

const servicesInfo = MASTERDATA.SERVICES_PACKAGE;
const alisha_charges = MASTERDATA.ALISHA_CHARGES;
const senior_charges = MASTERDATA.SENIOR_CHARGES;

export default class Services extends React.Component {

    state = {
        package_details: alisha_charges,
        alishaBtnSkin : "btn-active",
        seniorBtnSkin : "btn-inactive"
    }

    componentDidMount() {
        console.log(this.state.package_details.studio.bridal);
    }

    onTogglePackage = (e) => {
        if (e.target.getAttribute("btn-id") == "1") {
            this.setState({
                package_details: alisha_charges,
                alishaBtnSkin : "btn-active",
                seniorBtnSkin : "btn-inactive"
            });
        } else {
            this.setState({
                package_details: senior_charges,
                alishaBtnSkin : "btn-inactive",
                seniorBtnSkin : "btn-active"
            });
        }
    }

    render() {
        return (
            <div id="services-id">
                <section className="services-banner-section">
                    <div className="container-fluid bg-overlay">
                        <div className="container text-center">
                            <h1>OUR SERVICES</h1>
                        </div>
                        <div className="container mt-2 px-5">
                            <p>Alishaa wants people to feel like an absolute diva, there's nothing more attractive than a person owning the place they walk in with the way they carry themselves. With the use of top-notch hypoallergenic products that ensure skin safety so you’ll be ready to conquer the moment. </p>
                        </div>
                    </div>

                </section>

                <section className="our-services-section py-5">
                    <div className="container">
                        <div className="yellow-stroke m-auto"></div>
                        <h2 className="my-3">We do services</h2>
                        <div className="row my-5">

                            <div className="col-sm-4 px-3">
                                <img src={makeup} alt="makeup"></img>
                                <h3 className="mt-4">Makeup</h3>
                                <p>Here at Alisha Sah Makeup Studio  we have the confidence to make you confident.
                                     We take our work as our worship, there is no dissatisfaction when you come through
                                      our door</p>
                            </div>
                            <div className="col-sm-4 px-3">
                                <img src={hairstyle} alt="makeup"></img>
                                <h3 className="mt-4">Hair Stylings</h3>
                                <p>Rough edges, Thinning or you gave yourself an experimental late minute at home fringes?
                                     Don’t sweat it! We can take care of that. We offer a wide range of hairstyles 
                                     for any occasion </p>
                            </div>
                            <div className="col-sm-4 px-3">
                                <img src={brows} alt="makeup"></img>
                                <h3 className="mt-4">Brows & Lashes</h3>
                                <p>Get you brow game on with us, make those lashes look luscious. 
                                    Well maintained brows and lashes make a huge difference in your facial beauty.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="our-pricing-section py-5">
                    <div className="container">
                        <div className="yellow-stroke m-auto"></div>
                        <h2 className="my-3">Our Pricing</h2>
                        <p>Below are the plans for Alisha sat and senior stylist</p>
                        <div className="toggle-buttons">
                            <button className={this.state.alishaBtnSkin} onClick={this.onTogglePackage} btn-id={"1"}>ALISHA SAH</button>
                            <button className={this.state.seniorBtnSkin} onClick={this.onTogglePackage} btn-id={"2"}>SENIOR STYLISTS</button>
                        </div>
                        <div className="row my-5">
                            <div className="col-sm-4 mt-4">
                                <div className=  {this.state.alishaBtnSkin==='btn-active' ? 'box-card blue-box' : 'box-card orange-box'}>
                                    <img src={bridal} alt="makeup"></img>
                                    <h2 className="mt-4"><span>Bridal</span></h2>
                                    <div>
                                        <p>Studio</p>
                                        <span><b>₹{this.state.package_details.studio.bridal}</b></span>
                                    </div>
                                    <hr />
                                    <div>
                                        <p>Venue</p>
                                        <span><b>₹{this.state.package_details.venue.bridal}</b></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 mt-4">
                            <div className=  {this.state.alishaBtnSkin==='btn-active' ? 'box-card blue-box' : 'box-card orange-box'}>
                                    <img src={commercial} alt="makeup"></img>
                                    <h2 className="mt-4"><span>Occasional</span></h2>
                                    <p className="small-size">Engament/Reception/ Mehandi/Sangeet etc </p>
                                    <div>
                                        <p>Studio</p>
                                        <span><b>₹{this.state.package_details.studio.occasional}</b></span>
                                    </div>
                                    <hr />
                                    <div>
                                        <p>Venue</p>
                                        <span><b>₹{this.state.package_details.venue.occasional}</b></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 mt-4">
                            <div className=  {this.state.alishaBtnSkin==='btn-active' ? 'box-card blue-box' : 'box-card orange-box'}>
                                    <img src={occasional} alt="makeup"></img>
                                    <h2 className="mt-4"><span>Commercial Shoots</span></h2>
                                    <div>
                                        <p>Studio</p>
                                        <span><b>₹{this.state.package_details.studio.commercial}</b></span>
                                    </div>
                                    <hr />
                                    <div>
                                        <p>Venue</p>
                                        <span><b>₹{this.state.package_details.venue.commercial}</b></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row services-info">
                            {
                                Array.isArray(servicesInfo) && servicesInfo.map(({ title, desc }, index) => {
                                    return <div className="col-sm-12 mt-3">
                                        <h4 className="text-uppercase"><b>{title}</b></h4>
                                        <p>{desc}</p>
                                    </div>
                                })
                            }
                        </div>

                        <div className="row box-card blue-box">
                            <div className="col-sm">
                                <p><b>Travel & Accomodation</b> extra for any venue Makeup</p>
                                <p>Prices are inclusive or all taxes . All our charges are fixed and non negotiable. For further queries or bookings please contact us on +91-9313924758
                                </p>
                                <p>For Queries, Please Call or Whatsapp: <b>+91-9313924758</b></p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}