import React from "react";
import "./Contactus.css";
import portfolio1 from "../../images/portfolio1.png";
import emailjs from "emailjs-com";
import { Alert, Form, Col, Row, Button } from "react-bootstrap";

export default class Contactus extends React.Component {
  state = {
    name: "",
    email: "",
    phone: "",
    msg: "",
    alertInfo: null,
    subscriptionAlertInfo: null,
    subscribeName: "",
    subscribeMail: "",
    validated: false,
  };

  componentDidMount() {}

  subscribeEmail = async (e) => {
    const { subscribeName, subscribeMail } = this.state;
    e.preventDefault();
    this.setState({
      subscriptionAlertInfo: null,
    });
    if (!!subscribeMail) {
      try {
        const response = await fetch(
          "https://v1.nocodeapi.com/alishaasah/google_sheets/NgicNomsUMbDDeUv?tabId=Sheet1",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify([
              [subscribeName, subscribeMail, new Date().toLocaleString()],
            ]),
          }
        )
          .then(
            (response) => {
              console.log(response);
              this.setState({
                subscriptionAlertInfo: {
                  variant: "success",
                  msg: "Thanks for subscribing us. You will start receiving our newsletters!",
                },
                subscribeMail: "",
                subscribeName: "",
              });
            },
            (error) => {
              console.log(error);
              this.setState({
                subscriptionAlertInfo: {
                  variant: "danger",
                  msg: "There was an error while processing request. Please try again.",
                },
              });
            }
          )
          .catch((err) => console.log(err));
      } catch (e) {
        console.log("error " + e);
      }
    } else {
      this.setState({
        subscriptionAlertInfo: {
          variant: "danger",
          msg: "EmailID is mandatory",
        },
      });
      e.stopPropagation();
    }
  };

  contactUs = (event) => {
    const { name, phone, email, msg } = this.state;
    this.setState({
      alertInfo: null,
      validated: true,
    });
    if (!!name && !!phone && !!email) {
      const data = { name, phone, email, msg };
      emailjs.send(
          "service_7e349mf",
          "template_89pg7sa",
          data,
          "user_3ePbXecV1QxRQYUIcIlk6"
        )
        .then(
          (result) => {
            
            this.setState({
              alertInfo: {
                variant: "success",
                msg: "Thanks for contacting us. Our executive will call you shortly",
              },
              name: "",
              email: "",
              phone: "",
              msg: "",
            });
          },
          (error) => {
			  console.log('Error')
            this.setState({
              alertInfo: {
                variant: "danger",
                msg: "There was an error while processing request. Please try again.",
              },
            });
          }
        );
    } else {
      this.setState({
        alertInfo: {
          variant: "danger",
          msg: "Please fill all the mandatory details to submit the form",
        },
      });
      event.preventDefault();
      event.stopPropagation();
    }
    return;
  };

  renderAlert() {
    if (!this.state.alertInfo) {
      return null;
    }
    const {
      alertInfo: { variant, msg },
    } = this.state;
    return <Alert variant={variant}>{msg}</Alert>;
  }

  renderSubscriptionAlert() {
    if (!this.state.subscriptionAlertInfo) {
      return null;
    }
    const {
      subscriptionAlertInfo: { variant, msg },
    } = this.state;
    return <Alert variant={variant}>{msg}</Alert>;
  }

  render() {
    return (
      <div>
        <section className="contactus-section">
          <div className="container">
            <div className="row mt-5">
              <div className="col-sm-6 text-left mt-4">
                <div className="yellow-stroke "></div>
                <h2 className="my-3">Contact Now</h2>
                <p>
                  Please fill the sections below and our team will get in touch
                  with you ASAP.{" "}
                </p>
                
                <Form
                  noValidate
                  validated={this.state.validated}
                  
                >
                  <Form.Group
                    as={Row}
                    controlId="contactInfo"
                    value={this.state.service}
                  >
                    <Col>
                      <Form.Control
                        placeholder="Name"
                        value={this.state.name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        placeholder="Phone Number"
                        value={this.state.phone}
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="email"
                    value={this.state.service}
                  >
                    <Col>
                      <Form.Control
                        placeholder="Email"
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="Message">
                    <Col>
                      <Form.Control
                        as="textarea"
                        placeholder="Message"
                        value={this.state.msg}
                        onChange={(e) => this.setState({ msg: e.target.value })}
                      />
                    </Col>
                  </Form.Group>
                  {this.renderAlert()}
                 
                </Form>
                <Button
                    variant="primary"
                    type="submit"
                    className="btn btn-warning mt-3 p-2"
                    onClick = {this.contactUs}
                  >
                    Submit
                  </Button>
              </div>

              <div className="col-sm-6 text-left m-auto">
                <img src={portfolio1} alt="portfolio contact" />
              </div>
            </div>
          </div>
        </section>

        <section className="subscribe-section">
          <div className="container">
            <div className=" row">
              <div className="col-sm-6 text-left mt-4">
                <div className="yellow-stroke "></div>
                <h2 className="my-3">Subscribe Now - Don’t miss out!</h2>
                <p>
                  Subscribe to our news settler to receive information about our
                  classes, sessions, blog posts and new offers.
                </p>
                
                <form onSubmit={this.subscribeEmail}>
                  <div className="form-row">
                    <div className="form-group col-md-6 pr-5">
                      <input
                        type="text"
                        className="form-control"
                        id="inputNameSubscribe"
                        placeholder="Name"
                        value={this.state.subscribeName}
                        onChange={(e) =>
                          this.setState({ subscribeName: e.target.value,subscriptionAlertInfo: null })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6 pr-5">
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmailSubscribe"
                        placeholder="Email Id"
                        value={this.state.subscribeMail}
                        onChange={(e) =>
                          this.setState({ subscribeMail: e.target.value,subscriptionAlertInfo: null })
                        }
                      />
                    </div>
                  </div>
                  {this.renderSubscriptionAlert()}
                  <button type="submit" className="btn btn-warning mt-3 p-2">
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
