import React from 'react';
import './Home.css';
import portfolio1 from '../../images/portfolio1.png'
import badge1 from '../../images/badge.svg';
import badge2 from '../../images/badge2.svg';
import badge3 from '../../images/badge3.svg';
import badge4 from '../../images/badge4.svg';
import badge5 from '../../images/badge5.svg';
import badge6 from '../../images/badge.svg';
import portfolio2 from '../../images/alisha-courses.png';
import { Nav } from 'react-bootstrap';
export default class Home extends React.Component {
    render() {
        return (
            <div>
                <section className="homebanner-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 text-left m-auto banner-info">
                                <h1 className="outline-text">MAKEUP</h1>
                                <h1 className="bold-text">ARTIST</h1>
                                <p>Beauty lies in everyone, Let me introduce you to yours</p>
                                <a href = "#/booknow"> <button type="button" className="btn btn-outline-secondary">Book an appointment</button></a>
                            </div>
                            <div className="col-sm-6 mt-3">
                                <img className="pl-2" src={portfolio1} alt="portfolio1" />
                            </div>
                        </div>
                    </div>
                </section>

                {/*Alisha personal */}
                <section className="personals-section py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 text-left m-auto banner-info">
                            <img className="pl-2" src={portfolio2} alt="portfolio1" />
                            </div>
                            <div className="col-sm-6 text-left mt-3">
                                <div className="yellow-stroke mb-3"></div>
                                <h3 className="mb-3">Alishaa Sah</h3>
                                <p>Alisha Sah is a girl next door with a global ambition to make her art, her legacy, much like her mother who was a makeup artist too, Alisha grew this undying love for makeup since her early childhood days. </p>
                                <p>Sah, who was brought up in Surat, Gujrat believes in enhancing the natural beauty you already possess, her work ensures to make people do a double-take and look at your beautiful face again. Alisha is very sensible of the brands and products she endorses and uses as a professional make-up artist.
                                </p>
                                <p>Her vanity case is full of variety, from the best and the most affordable products in the industry to the posh and luxurious brands, everything that can make you look more stunning, Alisha has made sure she already knows all about it.</p>
                                <a href = "#/bio"> <button type="button" className="btn btn-outline-secondary">View Bio</button></a>

                                <div className="row mt-5">
                                    <div className="col-sm-6 d-flex banner-info">
                                        <h1 className="bold-text">95+</h1>
                                        <div className="ml-3">
                                            <p>Makeup<br /> Services</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 d-flex banner-info">
                                        <h1 className="bold-text">50+</h1>
                                        <div className="ml-3">
                                            <p>5 star<br /> Reviews</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*Alisha Links */}
                <section className="link-section py-5">
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-sm-4 text-left m-auto">
                                <div className="box-link mx-3">
                                    <a href="#/portfolio"><div className="pl-4">
                                        <img className="mt-5" src={badge1} alt="portfolio1" />
                                        <h2 className="mt-3">My work</h2>
                                        <p><u>View Portfolio</u></p>
                                    </div></a>
                                </div>
                            </div>
                            <div className="col-sm-4 text-left m-auto">
                                <div className="box-link mx-3">
                                <a href="#/services"><div className="pl-4">
                                        <img className="mt-5" src={badge2} alt="portfolio1" />
                                        <h2 className="mt-3">Services provided by me</h2>
                                        <p><u>View Services</u></p>
                                    </div></a>
                                </div>
                            </div>
                            <div className="col-sm-4 text-left m-auto">
                                <div className="box-link mx-3">
                                <a href="#/courses"><div className="pl-4">
                                        <img className="mt-5" src={badge3} alt="portfolio1" />
                                        <h2 className="mt-3">Courses Details</h2>
                                        <p><u>View Courses</u></p>
                                    </div></a>
                                </div>
                            </div>

                        </div>
                        <div className="row mt-5">
                            <div className="col-sm-4 text-left m-auto">
                                <div className="box-link mx-3">
                                <a href="#/contact"><div className="pl-4">
                                        <img className="mt-5" src={badge4} alt="portfolio1" />
                                        <h2 className="mt-3">Contact us</h2>
                                        <p><u>Contact us</u></p>
                                    </div></a>
                                </div>
                            </div>
                            <div className="col-sm-4 text-left m-auto">
                                <div className="box-link mx-3">
                                <a href="#/booknow"><div className="pl-4">
                                        <img className="mt-5" src={badge6} alt="portfolio1" />
                                        <h2 className="mt-3">Make me more prettier!</h2>
                                        <p><u>Book now</u></p>
                                    </div></a>
                                </div>
                            </div>
                            <div className="col-sm-4 text-left m-auto">
                                <div className="box-link mx-3">
                                <a href="#/blog" target={"_blank"}><div className="pl-4">
                                        <img className="mt-5" src={badge5} alt="portfolio1" />
                                        <h2 className="mt-3">Some Useful DIY</h2>
                                        <p><u>View Blogs</u></p>
                                    </div></a>
                                </div>
                            </div>
                           

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

