import React from 'react';
import './CancelPolicy.css'
export default class CancelPolicy extends React.Component {
    render() {
        return (
           <div>
             <p>Cancellation policy</p>
            </div>
        )
    }
}