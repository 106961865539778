import React from 'react';
import './Footer.css'
import fb from '../../images/facebook.svg';
import youtube from '../../images/youtube.svg';
import insta from '../../images/instagram.svg';
import linkedIn from '../../images/linkedin.svg';
import snapchat from '../../images/snapchat.svg';
import pintrest from '../../images/pinterest.svg';
import { Nav } from 'react-bootstrap';


export default class Footer extends React.Component {
    render() {
        return (
            <section className='footer-id bg-dark '>
                <div className="container-fluid">
                    <div className="row text-white">
                        <div className="col-sm-4 px-5 py-5 text-left ">
                            <img
                            src= '/images/logo-hori-white.png'
                            width="200"
                            className="d-inline-block align-top"
                            alt="alisha_logo"
                        />
                            <p className="mt-4"> Beauty lies in everyone, Let me introduce you to yours</p>
                            <div className="social-media d-flex mt-4">
                                <a href="https://www.instagram.com/Alishaasah" target="_blank"><img src={insta} alt="insta" /></a>
                                <a href="https://www.facebook.com/Alishaasah" target="_blank"><img src={fb} alt="fb" /></a>
                                <a href="https://in.pinterest.com/alishaasah" target="_blank"><img src={pintrest} alt="pintrest" /></a>
                                <a href="https://www.snapchat.com/add/Alishaasah" target="_blank"><img src={snapchat} alt="snapchat" /></a>
                                <a href="https://www.youtube.com/alishaasah" target="_blank"><img src={youtube} alt="youtube" /></a>
                                <a href="https://www.linkedin.com/in/Alishaasah" target="_blank"><img src={linkedIn} alt="linkedin" /></a>
                            </div>
                            <div className="mt-3">
                            <a href="#/terms" className="links">Terms & conditions</a>
                            <a href="#/cancelpolicy" className="links ml-4">Cancellation Policy</a>
                            </div>
                        </div>
                        <div className="col-sm-4 px-5 py-5 text-center">
                        <p className="mt-4 confit-address"><b>Makeup Studio Address - </b>
                                306/307,3rd floor Above Dhiraj Sons Richmond Plaza, Vesu, Surat, Gujarat 395007
                            </p>
                            <p className="mt-4 confit-address"><b>Salon Address - </b>
                            UG 14-15 Corner Point, City Light Rd, Athwa, Surat, Gujarat 39500
                            </p>
                        </div>
                        <div className="col-sm-4 px-5 py-5 text-right">
                        <a href = "#/booknow"> 
                            <button className="book-now">Book Now</button></a>
                            <p className="mt-4 confit-email">contact.alishaasah@gmail.com</p>
                            <p className="mt-2 confit-phone">+91 9313924758</p>
                            <p className="mt-2">© Copyright 2021</p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}