import React from "react";
import { Form, Col, Row, Button, Alert } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import emailjs from "emailjs-com";
import portfolio1 from "../../images/booknow.jpg";
import "./BookNow.css";
export default class BookNow extends React.Component {
  state = {
    name: "",
    service: "Bridal`",
    date: new Date(),
    email: "",
    phone: "",
    msg: "",
    address: "",
    studio: "Makeup Studio in Surat",
    makeup: "Alish Sha",
    validated: false,
    alertInfo: null,
  };

  sendEmail = (event) => {
    const form = event.currentTarget;
    const { name, phone, email, address } = this.state;
    this.setState({
      alertInfo: null,
			validated: true
    });
    if (!!name && !!phone && !!email && !!address) {
      const data = {
        ...this.state,
        date: moment(this.state.date).format("MMM Do YY"),
      };
      emailjs
        .send(
          "service_7e349mf",
          "template_jf44n1b",
          data,
          "user_3ePbXecV1QxRQYUIcIlk6"
        )
        .then(
          (result) => {
            this.setState({
              alertInfo: {
                variant: "success",
                msg: "Thanks for your booking. Our executive will call you shortly",
              },
              name: "",
              service: "Bridal`",
              date: new Date(),
              email: "",
              phone: "",
              msg: "",
              address: "",
              studio: "Makeup Studio in Surat",
              makeup: "Alish Sha",
            });
          },
          (error) => {
            this.setState({
              alertInfo: {
                variant: "danger",
                msg: "There was an error while processing request. Please try again.",
              },
            });
          }
        );
    } else {
      this.setState({
        alertInfo: {
          variant: "danger",
          msg: "Please fill all the mandatory details to submit the form",
        },
      });
      event.preventDefault();
      event.stopPropagation();
    }
    return;
  };

  renderAlert() {
    if (!this.state.alertInfo) {
      return null;
    }
    const {
      alertInfo: { variant, msg },
    } = this.state;
    return <Alert variant={variant}>{msg}</Alert>;
  }

  render() {
    return (
      <div className="Book__Now__Container">
        <div className="Image__Container">
          <img
            alt="Portfolio Img"
            src={portfolio1}
            width="100%"
            height="700px"
          />
        </div>
        <div className="Contact__Details">
          <div className="title-bar"></div>
          <div className="title">Book Now</div>
          <div>
            <p>
            Make a Booking Request.
            Please fill out the form below carefully with your info and someone from our team will get in touch with you, thank you.
            </p>
          </div>
          <Form
            noValidate
            validated={this.state.validated}
            className="Book__Form__Container"
            
          >
            <Form.Group as={Row} controlId="makeup" value={this.state.service}>
              <Form.Label column sm="4">
                <b>Prefered Makeup By</b>
              </Form.Label>
              <Col sm="8">
                <Row>
                  <Col sm="12">
                    <Form.Check
                      name="makeup"
                      inline
                      checked
                      label="Alishaa Sah"
                      type="radio"
                      id={`inline-radio-1`}
                      onChange={(e) => {
                        this.setState({ makeup: "Alish Sha" });
                      }}
                    />
                  </Col>
                  <Col sm="12">
                    <Form.Check
                      name="makeup"
                      inline
                      label="Senior makeup artist"
                      type="radio"
                      id={`inline-radio-1`}
                      onChange={(e) => {
                        this.setState({ makeup: "Senior makeup artist" });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                <b>Services you are interested</b>
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  controlId="formServices"
                  as="select"
                  className="FormSelect"
                  defaultValue="Choose..."
                  value={this.state.service}
                  custom
                  onChange={(e) => this.setState({ service: e.target.value })}
                >
                  <option value="Bridal">Bridal</option>
                  <option value="Occasional ( Mehandi / Sangeet/ Engament / reception/Party Makeup)">
                    Occasional ( Mehandi / Sangeet/ Engament / reception/Party
                    Makeup)
                  </option>
                  <option value="Commercial & Photoshoot makeup">
                    Commercial & Photoshoot makeup
                  </option>
                  <option value="Other services">Other services</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                <b>Contact Details</b>
              </Form.Label>
              <Col sm="8">
                <Form>
                  <Form.Group as={Row}>
                    <Col>
                      <Form.Control
                        placeholder="Name"
                        value={this.state.name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col>
                      <Form.Control
                        placeholder="Phone Number"
                        value={this.state.phone}
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formDetails">
                    <Col>
                      <Form.Control
                        placeholder="Email"
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        required
                      />
                    </Col>

                  </Form.Group>
                  <Form.Group as={Row} controlId="formDetails">
                    <Col>
                      <DatePicker
                        className="form-control"
                        selected={this.state.date}
                        customInput={<input type="text" />}
                        onChange={(date) => this.setState({ date })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formDetails">
                    <Col>
                      <Form.Control
                        as="select"
                        defaultValue="Choose..."
                        value={this.state.studio}
                        custom
                        className="FormSelect"
                        onChange={(e) =>
                          this.setState({ studio: e.target.value })
                        }
                        required
                      >
                        <option value="Makeup Studio in Surat">
                          Makeup Studio in Surat
                        </option>
                        <option value="Makeup At venue in surat">
                          Makeup At venue in surat
                        </option>
                        <option value="Makeup outside Surat">
                          Makeup outside Surat
                        </option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formDetails">
                    <Col>
                      <Form.Control
                        as="textarea"
                        placeholder="Venue Address"
                        value={this.state.address}
                        onChange={(e) =>
                          this.setState({ address: e.target.value })
                        }
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="formDetails"
                    value={this.state.msg}
                    onChange={(e) => this.setState({ msg: e.target.value })}
                  >
                    <Col>
                      <Form.Control as="textarea" placeholder="Message/Query" />
                    </Col>
                  </Form.Group>
                </Form>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formDetails">
              <Col sm="4"></Col>
              <Col>
                <div className="conditions-booknow">
                  <b>Please Note : </b>
                  <p>
                  This is only a booking request submission and non-binding for both parties. 
                  Our team will go through your booking and also check if artists are available 
                  on the dates requested. By clicking the book now, I accept the terms and conditions.
                  </p>
                </div>
                {this.renderAlert()}
                
              </Col>
            </Form.Group>
          </Form>
          <Button
                  variant="primary"
                  type="submit"
                  className="book-now-btn"
                  onClick = {this.sendEmail}
                >
                  Book Now
                </Button>
					
        </div>
      </div>
    );
  }
}
